import { Avatar, Box, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import useStoredFilterModel from '../../../hooks/useStoredFilterModel'
import { useLazyListAdminMetadataFieldsQuery } from '../../common-api/metadataFieldApiSlice'
import CreateOrUpdateMetadataFieldDialog from './CreateOrUpdateMetadataFieldDialog'
import DeleteMetadataFieldsDialog from './DeleteMetadataFieldsDialog'
import MetadataFieldGridToolbar from './MetadataFieldGridToolbar'
import {
    receivedMetadataFieldsList,
    selectMetadataFieldRows,
    selectTotalMetadataFields,
} from './adminMetadataFieldSlice'

export default function MetadataFieldList() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const metadataFieldRows = useAppSelector(selectMetadataFieldRows)
    const totalCount = useAppSelector(selectTotalMetadataFields)
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
    const [openCreateOrUpdateMetadataFieldDialog, setOpenCreateOrUpdateMetadataFieldDialog] = useState(false)
    const [openDeleteMetadataFieldsDialog, setOpenDeleteMetadataFieldsDialog] = useState(false)
    const [selectedMetadataFieldId, setSelectedMetadataFieldId] = useState<number | null>(null)

    const [filterModel, setFilterModel] = useStoredFilterModel('metadatafield_list', {
        items: [],
        quickFilterLogicOperator: undefined,
        quickFilterValues: [],
    })

    const [listAdminMetadataFieldsApi, { isLoading: isFetching }] = useLazyListAdminMetadataFieldsQuery()
    const theme = useTheme()
    const { width = 0 } = useWindowSize()

    const renderSpecialStatus = (params: GridRenderCellParams) => {
        if (params.row['isPatientId']) {
            return (
                <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                    <Typography variant={'body2'} sx={{ mr: 1 }}>
                        {params.value}
                    </Typography>
                    <Tooltip title={'Patient ID'}>
                        <Avatar
                            sx={{ width: 16, height: 16, fontSize: '0.70rem', bgcolor: theme.palette.primary.main }}
                        >
                            P
                        </Avatar>
                    </Tooltip>
                </Box>
            )
        } else if (params.row['isSampleId']) {
            return (
                <Box sx={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                    <Typography variant={'body2'} sx={{ mr: 1 }}>
                        {params.value}
                    </Typography>
                    <Tooltip title={'Sample ID'}>
                        <Avatar sx={{ width: 16, height: 16, fontSize: '0.70rem', bgcolor: theme.palette.info.main }}>
                            S
                        </Avatar>
                    </Tooltip>
                </Box>
            )
        }
        return params.value
    }

    const columns = [
        {
            field: 'name',
            headerName: t('name'),
            groupable: false,
            width: 250,
            renderCell: renderSpecialStatus,
        },
        {
            field: 'index',
            headerName: t('index'),
            groupable: false,
            width: 100,
        },
        {
            field: 'type',
            headerName: t('type'),
            groupable: false,
            width: 120,
        },
        {
            field: 'ontology',
            headerName: t('ontology'),
            groupable: false,
            filterable: true,
            width: 150,
        },
        {
            field: 'options',
            headerName: t('options'),
            groupable: false,
            filterable: true,
            width: 250,
        },
        {
            field: 'description',
            headerName: t('description'),
            groupable: false,
            filterable: true,
            width: Math.max(width - 1450, 250),
        },
        {
            field: 'standard',
            headerName: t('standard'),
            groupable: false,
            filterable: true,
            width: 100,
            type: 'singleSelect',
            valueOptions: ['Yes', 'No'],
        },
        {
            field: 'visibleByDefault',
            headerName: t('visibleByDefault'),
            groupable: false,
            filterable: true,
            width: 120,
            type: 'singleSelect',
            valueOptions: ['Yes', 'No'],
        },
        {
            field: 'createdBy',
            headerName: t('createdBy'),
            groupable: false,
            filterable: false,
            width: 150,
        },
        {
            field: 'createdAt',
            headerName: t('createdAt'),
            groupable: false,
            filterable: false,
            width: 130,
        },
        {
            field: 'updatedAt',
            headerName: t('updatedAt'),
            groupable: false,
            filterable: false,
            width: 130,
        },
    ] as GridColDef[]

    const fetchMetadataFields = async () => {
        const result = await listAdminMetadataFieldsApi().unwrap()
        dispatch(
            receivedMetadataFieldsList({
                metadataFieldList: result,
            }),
        )
    }

    useEffect(() => {
        void fetchMetadataFields()
    }, [])

    const deletable = useMemo(() => {
        return (
            selectionModel.length > 0 &&
            metadataFieldRows.filter((o) => selectionModel.includes(o.id) && (o.standard == 'Yes' || o.inUse)).length ==
            0
        )
    }, [selectionModel])

    return (
        <Box sx={{ width: '100%' }}>
            <DataGridPremium
                sx={{
                    border: 0,
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#EEE',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        pt: 1,
                        pb: 1,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0,
                    },
                }}
                rows={metadataFieldRows}
                rowCount={totalCount}
                columns={columns}
                disableAggregation
                disableColumnSelector
                loading={isFetching}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel)
                }}
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                checkboxSelection
                keepNonExistentRowsSelected
                pageSizeOptions={[10, 25, 50, 100]}
                pagination={true}
                disableMultipleColumnsSorting
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                }}
                slots={{
                    toolbar: MetadataFieldGridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        refreshCallback: () => {
                            void fetchMetadataFields()
                        },
                        createCallback: () => {
                            setSelectionModel([])
                            setSelectedMetadataFieldId(null)
                            setOpenCreateOrUpdateMetadataFieldDialog(true)
                        },
                        updateCallback: () => {
                            if (selectionModel.length == 1) {
                                setSelectedMetadataFieldId(selectionModel[0] as number)
                                setOpenCreateOrUpdateMetadataFieldDialog(true)
                            }
                        },
                        deleteCallback: () => {
                            if (deletable) {
                                setOpenDeleteMetadataFieldsDialog(true)
                            }
                        },
                        disableUpdate: selectionModel.length != 1,
                        disableDelete: !deletable,
                    },
                }}
            />
            <CreateOrUpdateMetadataFieldDialog
                openDialog={openCreateOrUpdateMetadataFieldDialog}
                handleCloseDialog={() => {
                    setOpenCreateOrUpdateMetadataFieldDialog(false)
                }}
                metadataFieldId={selectedMetadataFieldId}
            />
            <DeleteMetadataFieldsDialog
                openDialog={openDeleteMetadataFieldsDialog}
                handleCloseDialog={() => {
                    setOpenDeleteMetadataFieldsDialog(false)
                }}
                selectionModel={selectionModel}
                onDone={() => {
                    setSelectionModel([])
                }}
            />
        </Box>
    )
}
