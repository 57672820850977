import { apiSlice } from '../../app/apiSlice'
import { GeneListResult } from '../../model/model'

type GetGeneListRequest = {
    organism: string
}

export const geneSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getGeneList: builder.query<GeneListResult, GetGeneListRequest>({
            query: (req) => ({
                url: '/private/analysis/get-gene-list',
                params: { o: req.organism },
            }),
        }),
    }),
})

export const {
    useGetGeneListQuery,
    endpoints: { getGeneList },
} = geneSlice
