import { GridRowModel } from '@mui/x-data-grid-premium'
import { ArrayType1D, ArrayType2D } from 'danfojs/dist/danfojs-base/shared/types'
import { Dispatch, SetStateAction } from 'react'
import { UpSetModel } from '../model/model'

export const scrollToId = (elementId: string) => {
    const element = document.getElementById(elementId)
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
    }
}

export const resetErrors = (...setters: Dispatch<SetStateAction<boolean>>[]) => {
    setters.forEach((s) => {
        s.call(false, false)
    })
}

export type WithID = {
    id: number | string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getUniqueListById(arr: (WithID & any)[]) {
    return [...new Map(arr.map((item) => [item.id, item])).values()]
}

export function numberInInterval(s: string | null | undefined, min: number, max: number): boolean {
    if (!s) {
        return false
    }
    const ps = parseInt(s)
    return !(isNaN(ps) || ps < min || ps > max)
}

export function toAnalysisKey(s: string): string {
    const re = /[^A-Za-z0-9_-]+/
    return s.trim().toLowerCase().replace(re, '_')
}

export function splitGenes(geneString: string): string[] {
    // Split the string using a regular expression that matches common separators -> commas, semicolons, colons, and whitespace
    const separators = /[,;:\s]+/
    const genes = geneString.split(separators)
    // Filter out empty strings
    return genes.filter((gene) => gene.trim() !== '')
}

export function diff<T>(a1: T[], a2: T[]): T[] {
    return a1.filter((item) => !a2.includes(item))
}

export function isValidRegex(pattern: string): boolean {
    try {
        new RegExp(pattern)
        return true
    } catch (e) {
        return false
    }
}

export function isAlphanumeric(str: string): boolean {
    return /^[a-zA-Z0-9]+$/.test(str)
}

export function getDefaultMarkerSize(n: number, begin: 'small' | 'medium' | 'large' = 'medium'): number {
    const lower = begin == 'small' ? 2 : begin == 'medium' ? 4 : 6
    const upper = 12
    const rate = 200
    const size = lower + (upper - lower) * Math.exp(-n / rate)
    return Math.max(lower, Math.min(size, upper))
}

export function removeNullKeys(obj: Record<string, unknown>): Record<string, unknown> {
    // Iterate over all object keys
    Object.keys(obj).forEach((key) => {
        // If the value is null, delete the key
        if (obj[key] === null) {
            delete obj[key]
        }
        // If the value is an object, recursively call removeNullKeys
        else if (typeof obj[key] === 'object') {
            removeNullKeys(obj[key] as Record<string, unknown>)
        }
    })
    return obj
}

export function idCast(id: string | number): number {
    if (typeof id == 'string') {
        id = parseInt(id)
    }

    return id
}

export function idArrayCast(ids: (number | string)[]): number[] {
    const res: number[] = []
    for (let id of ids) {
        id = idCast(id)

        if (id) {
            res.push(id)
        }
    }

    return res
}

/**
 * Gets the value of a cookie by name.
 * @param name - The name of the cookie.
 * @returns The value of the cookie, or null if not found.
 */
export function getCookieValue(name: string): string | null {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
        return parts.pop()?.split(';').shift() || null
    }
    return null
}

export function removeCookie(name: string) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export function reverseStructure(
    data: GridRowModel[],
    col1Name: string,
    col2Name: string,
    sep: string = ';',
): UpSetModel[] {
    const geneMap: { [key: string]: string[] } = {}

    data.forEach((entry) => {
        entry[col1Name].split(sep).forEach((gene: string) => {
            if (!geneMap[gene]) {
                geneMap[gene] = []
            }
            geneMap[gene].push(entry[col2Name])
        })
    })

    return Object.keys(geneMap).map((gene) => ({
        name: gene,
        sets: geneMap[gene],
    }))
}

export function getMaxAbsoluteValue(input: ArrayType1D | ArrayType2D): number {
    // Flatten the array if it's ArrayType2D
    let flattenedArray: (string | number | boolean)[]

    if (Array.isArray(input[0])) {
        // input is ArrayType2D (array of arrays)
        flattenedArray = (input as ArrayType2D).flat()
    } else {
        // input is ArrayType1D (simple array)
        flattenedArray = input as ArrayType1D
    }

    // Filter the flattened array to keep only numbers
    const numericValues = flattenedArray.filter((value): value is number => typeof value === 'number')

    // Calculate the maximum absolute value
    let maxAbsValue = -Infinity
    for (const num of numericValues) {
        const absNum = Math.abs(num)
        if (absNum > maxAbsValue) {
            maxAbsValue = absNum
        }
    }
    return maxAbsValue
}

export function roundToNextTen(num: number): number {
    return Math.ceil(num / 10) * 10
}

export function isUUID(uuid: string): boolean {
    const uuidRegex = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/
    return uuidRegex.test(uuid)
}
