import React, { createContext, useEffect, useRef, useState } from 'react'
import { selectToken } from '../features/auth/authSlice'
import { ResponseMessage } from '../model/webSocketCommands'
import { getBaseWebsocketURL } from '../utils/platform'
import { useAppDispatch, useAppSelector } from './hooks'
import { IInternalWSSlice, Triggerer, wsSlice } from './wsSlice'
import useStoredRegion from '../hooks/useStoredRegion'

interface WebSocketContextInterface {
    webSocket: WebSocket | null
}

enum ConnectionsStatusEnum {
    CONNECTING = 0,
    OPEN = 1,
    CLOSING = 2,
    CLOSE = 3,
    ERROR = 4,
}

const WebSocketContext = createContext<WebSocketContextInterface>({} as WebSocketContextInterface)

const WebSocketProvider = ({ children }: { children: React.JSX.Element }) => {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectToken)
    const [socket, setSocket] = useState(null as WebSocket | null)
    const connectionsStatus = useRef<Record<string, number>>({})
    const retryIntervalRef = useRef<NodeJS.Timeout | null>(null)
    const [region] = useStoredRegion()

    const stopRetryInterval = () => {
        if (retryIntervalRef.current) {
            clearInterval(retryIntervalRef.current)
            retryIntervalRef.current = null
        }
    }

    const handleOpen = function (this: WebSocket) {
        setSocket(this)
        connectionsStatus.current[this.url] = ConnectionsStatusEnum.OPEN
        stopRetryInterval()
    }

    const handleRetryClose = function (this: WebSocket) {
        setSocket(null)
        connectionsStatus.current[this.url] = ConnectionsStatusEnum.CLOSE

        // Start retry attempts every 3 seconds.
        if (region && token && !retryIntervalRef.current) {
            retryIntervalRef.current = setInterval(() => {
                if (token) {
                    connectWs(region, token)
                    console.log('Reconnecting WebSocket...')
                }
            }, 5000)
        }
    }

    const handleError = function (this: WebSocket) {
        setSocket(null)
        connectionsStatus.current[this.url] = ConnectionsStatusEnum.ERROR
    }

    const handleMessageFunc = function (triggerer: Triggerer | undefined) {
        return (event: MessageEvent<string>) => {
            try {
                const structuredData = JSON.parse(event.data) as ResponseMessage
                triggerer?.(structuredData, dispatch)
            } catch (error) {
                console.error(error)
            }
        }
    }

    const createAndRegisterWs = (url: string, triggerer: Triggerer) => {
        connectionsStatus.current[url] = ConnectionsStatusEnum.CONNECTING

        const newSocket = new WebSocket(url)
        newSocket.addEventListener('open', handleOpen)
        newSocket.addEventListener('close', handleRetryClose)
        newSocket.addEventListener('error', handleError)
        newSocket.addEventListener('message', handleMessageFunc(triggerer))
    }

    const closeWs = () => {
        if (socket) {
            connectionsStatus.current[socket.url] = ConnectionsStatusEnum.CLOSING
            socket.close()
        }
    }

    const connectWs = (region: string, token: string) => {
        const url = `${getBaseWebsocketURL(region)}/ws/talk?token=${token}`
        if (url in connectionsStatus.current && connectionsStatus.current[url] < 2) {
            return
        }

        const triggerer = (wsSlice as IInternalWSSlice).getTriggerer()

        closeWs()
        createAndRegisterWs(url, triggerer)
    }

    useEffect(() => {
        if (region && token) {
            connectWs(region, token)
            return () => {
                closeWs()
            }
        }

        // Stop retry interval when token is not available.
        stopRetryInterval()
    }, [region, token])

    return (
        <WebSocketContext.Provider
            value={{
                webSocket: socket,
            }}
        >
            {children}
        </WebSocketContext.Provider>
    )
}

export { WebSocketContext, WebSocketProvider }
