import { apiSlice } from '../../app/apiSlice'
import { ApiKey } from '../../model/model'

export type CreateApiKeyRequest = {
    label: string
    duration: number
}

export const apiKeyApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createApiKey: builder.mutation<ApiKey, CreateApiKeyRequest>({
            query: (req) => ({
                url: '/private/api-key/create',
                method: 'POST',
                body: req,
            }),
        }),
        deleteApiKeys: builder.mutation<number[], number[]>({
            query: (req) => ({
                url: '/private/api-key/delete',
                method: 'POST',
                body: req,
            }),
        }),
        listApiKeys: builder.query<ApiKey[], void>({
            query: () => '/private/api-key/list',
        }),
    }),
})

export const {
    useCreateApiKeyMutation,
    useDeleteApiKeysMutation,
    useListApiKeysQuery,
    endpoints: { createApiKey, deleteApiKeys, listApiKeys },
} = apiKeyApiSlice
