import {
    Action,
    createAsyncThunk,
    createListenerMiddleware,
    createSlice,
    isRejectedWithValue,
    PayloadAction,
} from '@reduxjs/toolkit'
import i18n from 'i18next'
import { AppDispatch, RootState } from '../../app/store'
import { getRoleList } from '../admin/role/roleApiSlice'
import { changeUsersActivationStatus, createOrUpdateUser, listUsers } from '../admin/user/adminUserApiSlice'
import { getGeneList } from '../common-api/geneSlice'
import { getMetadataFields } from '../common-api/metadataFieldApiSlice'
import { getOntologyClassListByName, getOntologyOptions } from '../common-api/ontologyApiSlice'
import {
    createDashboard,
    deleteDashboard,
    listDashboards,
    updateDashboardName,
} from '../omicsbrowser/dashboardApiSlice'
import {
    clearAllDashboardFilters,
    deleteDashboardFilter,
    getDashboardFilterField,
    saveDashboardFilter,
    updateDashboardLogicalOperator,
} from '../omicsbrowser/filters/dashboardFiltersApiSlice'
import { createProject, deleteProject, getProjects, listProjects, updateProject } from '../project/projectApiSlice'
import { createRuntime, getActiveRuntimeInfo, getRuntimeOptions, terminateRuntime } from '../runtime/runtimeApiSlice'
import {
    addSamplesToProject,
    deleteSamples,
    listSamples,
    markSamplesAsReady,
    removeSamplesFromProject,
    saveSampleMetadata,
    uploadMetadataFile,
    uploadSample,
} from '../sample/sampleApiSlice'
import { createApiKey, deleteApiKeys, listApiKeys } from '../user/apiKeyApiSlice'
import {
    acceptEULA,
    changePassword,
    getUserWithLicense,
    updateEmail,
    updatePhone,
    updateUserProp,
} from '../user/userApiSlice'
import { uploadCellAnnotationsFile } from '../workbench/analysis/transcriptomics/modalities/singlecell/scRnaSeqAnalysisApiSlice'

type AppMessageType = 'error' | 'warning' | 'info' | 'success' | undefined
type AppMessage = string | null | undefined

type AppMessageState = {
    message: string | null | undefined
    type: AppMessageType
    open: boolean
    navigationPath: string | null | undefined
}

const initialState = {
    type: undefined,
    message: null,
    open: false,
    navigationPath: null,
} as AppMessageState

const appMessageSlice = createSlice({
    name: 'appMessageHolder',
    initialState: initialState,
    reducers: {
        setAppMessage: (
            state,
            {
                payload: { type, message, navigationPath },
            }: PayloadAction<{
                type: AppMessageType
                message: string | null | undefined
                navigationPath?: string | null | undefined
            }>,
        ) => {
            state.open = true
            state.type = type
            state.message = message
            state.navigationPath = navigationPath
        },
        clearAppMessage: (state) => {
            state.open = false
        },
        clearAppMessageIf: (
            state,
            {
                payload: { type, message },
            }: PayloadAction<{
                type: AppMessageType
                message: AppMessage
            }>,
        ) => {
            if (state.open && state.type === type && state.message === message) {
                state.open = false
            }
        },
    },
})

export const receivedAppMessage = createAsyncThunk<
    void,
    { type: AppMessageType; message: AppMessage; navigationPath?: string | null; time?: number },
    { dispatch: AppDispatch; state: RootState }
>('appMessageSlice/receivedAppMessage', async ({ type, message, navigationPath, time }, { dispatch }) => {
    dispatch(
        appMessageSlice.actions.setAppMessage({
            type,
            message,
            navigationPath,
        }),
    )

    if (time == undefined) {
        const matches = message?.match(/\b\w+\b/g)
        time = matches ? matches.length : 1
        time *= 1000
    }

    return new Promise<void>((resolve) => {
        setTimeout(() => {
            dispatch(
                appMessageSlice.actions.clearAppMessageIf({
                    type,
                    message,
                }),
            )
            resolve()
        }, time)
    })
})

export const errorListenerMiddleware = createListenerMiddleware()

interface AppError {
    code: string
}

errorListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()({
    predicate: (action) => isRejectedWithValue(action),
    effect: async (action, listenerApi) => {
        const msg = 'Error loading data.'
        if (action.payload) {
            try {
                const payloadData = action.payload as {
                    data: unknown
                }

                let appError: AppError
                if (typeof payloadData.data === 'object') {
                    appError = payloadData.data as AppError
                } else {
                    appError = JSON.parse(payloadData.data as string) as AppError
                }
                listenerApi.dispatch(
                    receivedAppMessage({
                        type: 'error',
                        message: i18n.t(appError.code),
                    }),
                )
            } catch {
                listenerApi.dispatch(
                    receivedAppMessage({
                        type: 'error',
                        message: msg,
                    }),
                )
            }
        } else {
            // Deprecated: Add returned backend errors to the translation file for a message.
            actionSpecificErr(msg, action, listenerApi)
        }
    },
})

/**
 * @deprecated This is old and should be removed.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function actionSpecificErr(msg: string, action: Action, listenerApi: any) {
    if (getRoleList.matchRejected(action)) {
        msg = 'Error loading role list.'
    }
    if (getGeneList.matchRejected(action)) {
        msg = 'Error loading gene list.'
    }
    if (getProjects.matchRejected(action)) {
        msg = 'Error loading projects.'
    }
    if (listProjects.matchRejected(action)) {
        msg = 'Error loading projects.'
    }
    if (createProject.matchRejected(action)) {
        msg = 'Error creating project.'
    }
    if (updateProject.matchRejected(action)) {
        msg = 'Error updating project details.'
    }
    if (deleteProject.matchRejected(action)) {
        msg = 'Error deleting project.'
    }
    if (getMetadataFields.matchRejected(action)) {
        msg = 'Error loading metadata fields.'
    }
    if (getOntologyClassListByName.matchRejected(action) || getOntologyOptions.matchRejected(action)) {
        msg = 'Error loading ontology options.'
    }
    if (uploadCellAnnotationsFile.matchRejected(action)) {
        msg = 'Error uploading cell annotations file.'
    }
    if (listDashboards.matchRejected(action)) {
        msg = 'Error loading dashboards.'
    }
    if (createDashboard.matchRejected(action)) {
        msg = 'Error creating dashboard.'
    }
    if (deleteDashboard.matchRejected(action)) {
        msg = 'Error deleting dashboard.'
    }
    if (updateDashboardName.matchRejected(action)) {
        msg = 'Error updating dashboard name.'
    }
    if (getDashboardFilterField.matchRejected(action)) {
        msg = 'Error getting filter field.'
    }
    if (saveDashboardFilter.matchRejected(action)) {
        msg = 'Error saving filter.'
    }
    if (updateDashboardLogicalOperator.matchRejected(action)) {
        msg = 'Error updating filter operator.'
    }
    if (clearAllDashboardFilters.matchRejected(action)) {
        msg = 'Error clearing all filters.'
    }
    if (deleteDashboardFilter.matchRejected(action)) {
        msg = 'Error deleting filter.'
    }
    if (createApiKey.matchRejected(action)) {
        msg = 'Error creating API key.'
    }
    if (deleteApiKeys.matchRejected(action)) {
        msg = 'Error deleting API keys.'
    }
    if (listApiKeys.matchRejected(action)) {
        msg = 'Error loading API keys.'
    }
    if (getUserWithLicense.matchRejected(action)) {
        msg = 'Error loading user.'
    }
    if (acceptEULA.matchRejected(action)) {
        msg = 'Error accepting EULA.'
    }
    if (updateUserProp.matchRejected(action)) {
        msg = 'Error updating property.'
    }
    if (updateEmail.matchRejected(action)) {
        msg = 'Error updating email address.'
    }
    if (updatePhone.matchRejected(action)) {
        msg = 'Error updating phone number.'
    }
    if (changePassword.matchRejected(action)) {
        msg = 'Error changing password.'
    }
    if (listUsers.matchRejected(action)) {
        msg = 'Error loading users.'
    }
    if (changeUsersActivationStatus.matchRejected(action)) {
        msg = "Error changing users' activation status."
    }
    if (createOrUpdateUser.matchRejected(action)) {
        msg = 'Error creating or updating user.'
    }
    if (getRuntimeOptions.matchRejected(action)) {
        msg = 'Error loading runtime options.'
    }
    if (getActiveRuntimeInfo.matchRejected(action)) {
        msg = 'Error loading runtime info.'
    }
    if (createRuntime.matchRejected(action)) {
        msg = 'Error creating runtime.'
    }
    if (terminateRuntime.matchRejected(action)) {
        msg = 'Error terminating runtime.'
    }
    if (uploadSample.matchRejected(action)) {
        msg = 'Error uploading sample.'
    }
    if (uploadMetadataFile.matchRejected(action)) {
        msg = 'Error uploading metadata file.'
    }
    if (listSamples.matchRejected(action)) {
        msg = 'Error loading samples.'
    }
    if (deleteSamples.matchRejected(action)) {
        msg = 'Error deleting samples.'
    }
    if (saveSampleMetadata.matchRejected(action)) {
        msg = 'Error saving sample metadata.'
    }
    if (addSamplesToProject.matchRejected(action)) {
        msg = 'Error adding samples to project.'
    }
    if (removeSamplesFromProject.matchRejected(action)) {
        msg = 'Error removing samples from project.'
    }
    if (markSamplesAsReady.matchRejected(action)) {
        msg = 'Error marking samples as ready.'
    }

    listenerApi.dispatch(
        receivedAppMessage({
            type: 'error',
            message: msg,
        }),
    )
}

export const { clearAppMessage } = appMessageSlice.actions

export const selectAppMessageOpen = (state: RootState) => state.appMessageHolder.open
export const selectAppMessageType = (state: RootState) => state.appMessageHolder.type
export const selectAppMessage = (state: RootState) => state.appMessageHolder.message
export const selectAppNavigationPath = (state: RootState) => state.appMessageHolder.navigationPath

export default appMessageSlice.reducer
