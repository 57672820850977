import { apiSlice } from '../../../../../../app/apiSlice'
import { LightExecCommand } from '../../../../../../model/model'

export const scRnaSeqAnalysisApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        uploadCellAnnotationsFile: builder.mutation<LightExecCommand, FormData>({
            query: (data) => ({
                url: '/private/analysis/upload-cell-annotations',
                method: 'POST',
                body: data,
            }),
        }),
    }),
})

export const {
    useUploadCellAnnotationsFileMutation,
    endpoints: { uploadCellAnnotationsFile },
} = scRnaSeqAnalysisApiSlice
