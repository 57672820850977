import { GridFilterItem } from '@mui/x-data-grid'
import { apiSlice } from '../../app/apiSlice'
import {
    Project,
    ProjectAccess,
    ProjectAccessType,
    ProjectAutocompleteResult,
    ProjectVisibility,
} from '../../model/model'

type GetProjectsRequest = {
    keyword: string
    organizationId?: number
}

export type CreateProjectRequest = {
    externalId: string
    PMID: string
    title: string
    description: string
    overallDesign: string
    organizationVisibility: ProjectVisibility
    tags: string[]
}

export type CreateProjectResponse = {
    projectId: string
}

export type ListProjectsRequest = {
    pageNumber: number
    pageSize: number
    sortBy: string | null
    order: 'asc' | 'desc' | null | undefined
    logicalOperator?: 'and' | 'or' | undefined
    propertiesFilters: GridFilterItem[]
    arrayPropertiesFilters: GridFilterItem[]
    sampleMetadata: GridFilterItem[]
}

export type ListProjectsResponse = {
    projectList: Project[]
    projectAccessList: ProjectAccess[]
    totalCount: number
}

export type UpdateProjectDetailsRequest = {
    projectId: number
    externalId: string
    PMID: string
    title: string
    description: string
    overallDesign: string
    organizationVisibility: ProjectVisibility
    tags: string[]
}

export type UpdateProjectDetailsResponse = {
    project: Project
}

export type AddUserToProjectRequest = {
    projectId: number
    email: string
    accessLevel: ProjectAccessType
}

export type RemoveUserFromProjectRequest = {
    projectId: number
    userId: number
}

export type UpdateUserAccessLevelRequest = {
    projectId: number
    userId: number
    accessLevel: ProjectAccessType
}

export const projectApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProjects: builder.query<ProjectAutocompleteResult[], GetProjectsRequest>({
            query: (req) => ({
                url: '/private/project/autocomplete',
                params: { k: req.keyword, organizationId: req.organizationId },
            }),
        }),
        createProject: builder.mutation<CreateProjectResponse, CreateProjectRequest>({
            query: (req) => ({
                url: '/private/project/create',
                method: 'POST',
                body: req,
            }),
        }),
        updateProject: builder.mutation<UpdateProjectDetailsResponse, UpdateProjectDetailsRequest>({
            query: (req) => ({
                url: '/private/project/update',
                method: 'POST',
                body: req,
            }),
        }),
        deleteProject: builder.mutation<void, number>({
            query: (id) => ({
                url: '/private/project/delete',
                method: 'DELETE',
                params: { id: id },
            }),
        }),
        listProjects: builder.mutation<ListProjectsResponse, ListProjectsRequest>({
            query: (req) => ({
                url: '/private/project/list',
                method: 'POST',
                body: req,
            }),
        }),
        addUserToProject: builder.mutation<ProjectAccess, AddUserToProjectRequest>({
            query: (req) => ({
                url: '/private/project/add-member',
                method: 'POST',
                body: req,
            }),
        }),
        removeUserFromProject: builder.mutation<void, RemoveUserFromProjectRequest>({
            query: (req) => ({
                url: '/private/project/remove-member',
                method: 'DELETE',
                body: req,
            }),
        }),
        updateUserAccessLevel: builder.mutation<ProjectAccess, UpdateUserAccessLevelRequest>({
            query: (req) => ({
                url: '/private/project/update-member-access',
                method: 'PUT',
                body: req,
            }),
        }),
        requestAccess: builder.mutation<void, number>({
            query: (projectId) => ({
                url: '/private/project/request-access',
                method: 'POST',
                body: {
                    projectId,
                },
            }),
        }),
    }),
})

export const {
    useLazyGetProjectsQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
    useListProjectsMutation,
    useAddUserToProjectMutation,
    useRemoveUserFromProjectMutation,
    useUpdateUserAccessLevelMutation,
    useRequestAccessMutation,
    endpoints: { getProjects, createProject, updateProject, deleteProject, listProjects },
} = projectApiSlice
