import { GridFilterItem } from '@mui/x-data-grid'
import { apiSlice } from '../../app/apiSlice'
import { FileObject, Sample, SampleSource } from '../../model/model'
import { ExternalApiKeyProvider } from '../user/externalApiKeyApiSlice'
import { CombinedSamples, SampleEntry } from './import/model'

export type GenerateSamplesBatchIdResponse = {
    batchId: string
}

export type UploadSampleResponse = {
    error: boolean
    errorMessage?: string
}

export type ImportSamplesRequest = {
    sampleSource: SampleSource
    sampleEntryList: SampleEntry[]
}

export type ListSamplesRequest = {
    logicalOperator: 'and' | 'or' | undefined
    projectId?: number | null
    analysisId?: number | null
    propertiesFilters: GridFilterItem[]
    metadata: GridFilterItem[]
    patientFilters: GridFilterItem[]
    pageNumber: number
    pageSize: number
    sortBy: string | null
    order: 'asc' | 'desc' | null | undefined
}

export type ListSamplesResponse = {
    sampleList: Sample[]
    totalCount: number
}

export type SaveSampleMetadataRequest = {
    sampleMetadata: Record<number, Record<string, string>>
}

export type SaveSampleMetadataResponse = {
    sampleList: Sample[]
}

export type AddSamplesToProjectRequest = {
    sampleIdList: number[]
    projectId: number
}

export type AddSamplesToProjectResponse = unknown

export type RemoveSamplesFromProjectRequest = {
    sampleIdList: number[]
    projectId: number
}

export type RemoveSamplesFromProjectResponse = {
    sampleIdList: number[]
}

export type MarkSamplesAsReadyRequest = {
    sampleIdList: number[]
}

export type MarkSamplesAsReadyResponse = {
    sampleList: Sample[]
}

export type MarkSamplesAsAnnotatingRequest = MarkSamplesAsReadyRequest

export type MarkSamplesAsAnnotatingResponse = MarkSamplesAsReadyResponse

export type ListRemoteSamplesRequest = {
    bucket: string
    provider: ExternalApiKeyProvider
    folder: string
    regex: string
}

type UploadMetadataFileRequest = {
    data: FormData
    projectId?: number
}

export const sampleApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        generateBatchId: builder.query<GenerateSamplesBatchIdResponse, void>({
            query: () => ({
                url: '/private/sample/generate-batch-id',
                method: 'GET',
            }),
        }),
        uploadSample: builder.mutation<UploadSampleResponse, FormData>({
            query: (data) => ({
                url: '/private/sample/upload',
                method: 'POST',
                body: data,
            }),
        }),
        uploadCombinedSamples: builder.mutation<void, FormData>({
            query: (data) => ({
                url: '/private/sample/upload/combined',
                method: 'POST',
                body: data,
            }),
        }),
        importSamples: builder.mutation<void, ImportSamplesRequest>({
            query: (data) => ({
                url: '/private/sample/import',
                method: 'POST',
                body: data,
            }),
        }),
        importCombinedSamples: builder.mutation<void, CombinedSamples>({
            query: (data) => ({
                url: '/private/sample/import/combined',
                method: 'POST',
                body: data,
            }),
        }),
        uploadMetadataFile: builder.mutation<void, UploadMetadataFileRequest>({
            query: (data) => ({
                url: '/private/sample/upload-metadata',
                method: 'POST',
                body: data.data,
                params: {
                    projectId: data.projectId,
                },
            }),
        }),
        listSamples: builder.mutation<ListSamplesResponse, ListSamplesRequest>({
            query: (data) => ({
                url: '/private/sample/list',
                method: 'POST',
                body: data,
            }),
        }),
        listAllSampleIDs: builder.mutation<number[], Omit<ListSamplesRequest, 'pageSize' | 'pageNumber'>>({
            query: (data) => ({
                url: '/private/sample/list-all-ids',
                method: 'POST',
                body: data,
            }),
        }),
        deleteSamples: builder.mutation<number[], number[]>({
            query: (data) => ({
                url: '/private/sample/delete',
                method: 'POST',
                body: data,
            }),
        }),
        saveSampleMetadata: builder.mutation<SaveSampleMetadataResponse, SaveSampleMetadataRequest>({
            query: (data) => ({
                url: '/private/sample/save-metadata',
                method: 'POST',
                body: data,
            }),
        }),
        addSamplesToProject: builder.mutation<AddSamplesToProjectResponse, AddSamplesToProjectRequest>({
            query: (data) => ({
                url: '/private/sample/add-to-project',
                method: 'POST',
                body: data,
            }),
        }),
        removeSamplesFromProject: builder.mutation<RemoveSamplesFromProjectResponse, RemoveSamplesFromProjectRequest>({
            query: (data) => ({
                url: '/private/sample/remove-from-project',
                method: 'POST',
                body: data,
            }),
        }),
        markSamplesAsReady: builder.mutation<MarkSamplesAsReadyResponse, MarkSamplesAsReadyRequest>({
            query: (data) => ({
                url: '/private/sample/mark-as-ready',
                method: 'POST',
                body: data,
            }),
        }),
        markSamplesAsAnnotating: builder.mutation<MarkSamplesAsAnnotatingResponse, MarkSamplesAsAnnotatingRequest>({
            query: (data) => ({
                url: '/private/sample/mark-as-annotating',
                method: 'POST',
                body: data,
            }),
        }),
        listRemoteSamples: builder.query<FileObject[], ListRemoteSamplesRequest>({
            query: (req) => ({
                url: '/private/sample/remote',
                method: 'GET',
                params: {
                    bucket: req.bucket,
                    provider: req.provider,
                    folder: req.folder,
                    regex: req.regex,
                },
            }),
        }),
        countAnnotatingSamplesForProject: builder.query<number, number>({
            query: (projectId) => ({
                url: '/private/sample/count-project-annotating',
                method: 'GET',
                params: {
                    projectId: projectId,
                },
            }),
        }),
    }),
})

export const {
    useGenerateBatchIdQuery,
    useUploadSampleMutation,
    useUploadCombinedSamplesMutation,
    useImportSamplesMutation,
    useImportCombinedSamplesMutation,
    useUploadMetadataFileMutation,
    useListSamplesMutation,
    useListAllSampleIDsMutation,
    useDeleteSamplesMutation,
    useSaveSampleMetadataMutation,
    useAddSamplesToProjectMutation,
    useRemoveSamplesFromProjectMutation,
    useMarkSamplesAsReadyMutation,
    useMarkSamplesAsAnnotatingMutation,
    useLazyListRemoteSamplesQuery,
    useLazyCountAnnotatingSamplesForProjectQuery,
    endpoints: {
        uploadSample,
        uploadMetadataFile,
        listSamples,
        deleteSamples,
        saveSampleMetadata,
        addSamplesToProject,
        removeSamplesFromProject,
        markSamplesAsReady,
    },
} = sampleApiSlice
