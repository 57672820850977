import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit'
import { RootState } from '../../../app/store'
import { MetadataField, MetadataFieldDetail } from '../../../model/model'

const metadataFields = createEntityAdapter<MetadataFieldDetail, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

type MetadataFieldListState = {
    metadataFields: EntityState<MetadataFieldDetail, number>
}

const initialState = {
    metadataFields: metadataFields.getInitialState(),
} as MetadataFieldListState

const metadataFieldListSlice = createSlice({
    name: 'metadataFieldListHolder',
    initialState: initialState,
    reducers: {
        receivedMetadataFieldsList: (
            state,
            { payload: { metadataFieldList } }: PayloadAction<{ metadataFieldList: MetadataFieldDetail[] }>,
        ) => {
            metadataFields.setAll(state.metadataFields, metadataFieldList)
        },
        receivedNewMetadataField: (
            state,
            { payload: { metadataField } }: PayloadAction<{ metadataField: MetadataFieldDetail }>,
        ) => {
            metadataFields.setOne(state.metadataFields, metadataField)
        },
        receivedUpdatedMetadataField: (
            state,
            { payload: { metadataField } }: PayloadAction<{ metadataField: MetadataFieldDetail }>,
        ) => {
            metadataFields.setOne(state.metadataFields, metadataField)
        },
        receivedDeletedMetadataFields: (
            state,
            { payload: { metadataFieldIdList } }: PayloadAction<{ metadataFieldIdList: number[] }>,
        ) => {
            metadataFields.removeMany(state.metadataFields, metadataFieldIdList)
        },
    },
})

export const {
    receivedMetadataFieldsList,
    receivedNewMetadataField,
    receivedUpdatedMetadataField,
    receivedDeletedMetadataFields,
} = metadataFieldListSlice.actions

export const {
    selectAll: selectAllMetadataFields,
    selectById: selectMetadataFieldById,
    selectIds: selectMetadataFieldIds,
    selectTotal: selectTotalMetadataFields,
    selectEntities: selectMetadataFieldEntities,
} = metadataFields.getSelectors<RootState>((state) => state.metadataFieldListHolder.metadataFields)

export const selectMetadataFieldRows = createSelector([selectAllMetadataFields], (metadataFields) => {
    return metadataFields.map((metadataField: MetadataFieldDetail) => {
        return {
            id: metadataField.id,
            name: metadataField.name,
            type: metadataField.type,
            standard: metadataField.standard ? 'Yes' : 'No',
            ontology: metadataField.ontology?.name,
            options: metadataField.options?.filter((o) => o != '').join(', '),
            description: metadataField.description,
            index: metadataField.index,
            inUse: metadataField.inUse,
            createdBy: `${metadataField.user.firstName} ${metadataField.user.lastName}`,
            createdAt: metadataField.createdAt.parseAndFormatDate(),
            updatedAt: metadataField.updatedAt.parseAndFormatDate(),
            isPatientId: metadataField.isPatientId,
            isSampleId: metadataField.isSampleId,
            visibleByDefault: metadataField.visibleByDefault ? 'Yes' : 'No',
        }
    })
})

export const selectIds = (state: RootState, ids: EntityId[]) => ids

export const selectMetadataFieldsByIds = createSelector([selectAllMetadataFields, selectIds], (metadataFields, ids) => {
    return metadataFields.filter((metadataField: MetadataField) => {
        return ids.includes(metadataField.id)
    })
})

export default metadataFieldListSlice.reducer
