import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as React from 'react'
import { useMemo } from 'react'
import {
    AnalysisResultNotificationProps,
    CanceledCommand,
    DoneCommand,
    FailedCommand,
    MicroarrayWorkflow,
    RnaSeqWorkflow,
    ScRnaSeqWorkflow,
    TerminatedCommand,
} from '../../../model/model'
import {
    ComputeStatistics,
    DifferentialExpression,
    DifferentialPathwayActivity,
    GeneSetEnrichment,
    ImportDifferentialExpression,
    PlotMarkerGenes,
    RnaSeqQcGeneSelection,
    ScRnaSeqCellComposition,
    ScRnaSeqQcCellSelection,
    ScRnaSeqQcGeneSelection,
    WeightedGeneCoExpressionNetworkAnalysis,
} from '../../../model/analysisComponents'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import NotificationContent, { NotificationParams } from './NotificationContent'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import ExpandableText from '../../common/ExpandableText'
import { Box, Typography } from '@mui/material'

export default function AnalysisResult({ notification, markAsRead }: NotificationParams) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const properties = useMemo((): AnalysisResultNotificationProps => {
        return notification.properties as unknown as AnalysisResultNotificationProps
    }, [notification])

    const explorerFlavor = useMemo(() => {
        switch (properties.workflow) {
            case RnaSeqWorkflow:
                return 'sample-explorer'
            case MicroarrayWorkflow:
                return 'sample-explorer'
            case ScRnaSeqWorkflow:
                return 'cell-explorer'
            default:
                return ''
        }
    }, [properties])

    const componentUri = useMemo((): string => {
        switch (properties.component) {
            case DifferentialExpression:
            case ImportDifferentialExpression:
                return 'deg'
            case DifferentialPathwayActivity:
                return 'dap'
            case GeneSetEnrichment:
                return 'gsea'
            case WeightedGeneCoExpressionNetworkAnalysis:
                return 'wgcna'
            case PlotMarkerGenes:
                return 'marker-genes'
            case ScRnaSeqCellComposition:
                return 'cell-composition'
            case ComputeStatistics:
                return 'compute-statistics'
            case RnaSeqQcGeneSelection:
            case ScRnaSeqQcGeneSelection:
            case ScRnaSeqQcCellSelection:
                return 'qc'
            default:
                return explorerFlavor
        }
    }, [properties])

    const workflowUri = useMemo((): string => {
        switch (properties.workflow) {
            case RnaSeqWorkflow:
                return 'rnaseq'
            case MicroarrayWorkflow:
                return 'microarray'
            case ScRnaSeqWorkflow:
                return 'scrnaseq'
            default:
                return ''
        }
    }, [properties])

    const icon = useMemo(() => {
        switch (properties.status) {
            case DoneCommand:
                return <CheckIcon color={'primary'} />
            case CanceledCommand:
                return <CancelPresentationIcon color={'primary'} />
            case FailedCommand:
                return <ErrorOutlineIcon color={'error'} />
            case TerminatedCommand:
                return <ErrorOutlineIcon color={'warning'} />
            default:
                return <></>
        }
    }, [properties])

    return (
        <NotificationContent
            notification={notification}
            onClick={() => {
                if (!notification.read) {
                    markAsRead(notification.id)
                }
                if (!componentUri) {
                    return
                }
                if (
                    properties.status == FailedCommand ||
                    properties.status == TerminatedCommand ||
                    properties.status == CanceledCommand
                ) {
                    navigate(`/analysis/${workflowUri}/${properties.analysisId}/${componentUri}`)
                } else if (properties.status == DoneCommand) {
                    navigate(
                        `/analysis/${workflowUri}/${properties.analysisId}/${componentUri}?key=${properties.computationResultKey}`,
                    )
                }
            }}
            icon={icon}
            title={
                <>
                    {properties.status == FailedCommand && <>Analysis Command Failed</>}
                    {properties.status == TerminatedCommand && <>Analysis Command Terminated</>}
                    {properties.status == DoneCommand && <>Analysis Command Done</>}
                    {properties.status == CanceledCommand && <>Analysis Command Canceled</>}
                </>
            }
        >
            <>
                {properties.status == FailedCommand && (
                    <Box>
                        <Typography variant={'body2'}>
                            <i>{t(properties.component)}</i> failed in project <b>{properties.projectTitle}</b>.
                        </Typography>
                        <ExpandableText
                            text={properties.error}
                            sx={{
                                fontFamily:
                                    'Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif',
                            }}
                        />
                    </Box>
                )}
                {properties.status == TerminatedCommand && (
                    <Box>
                        <Typography variant={'body2'}>
                            <i>{t(properties.component)}</i> was terminated due to runtime idle settings for analysis in
                            project <b>{properties.projectTitle}</b>
                        </Typography>
                    </Box>
                )}
                {properties.status == DoneCommand && (
                    <Box>
                        <Typography variant={'body2'}>
                            <i>{t(properties.component)}</i> completed successfully in project{' '}
                            <b>{properties.projectTitle}</b>
                        </Typography>
                    </Box>
                )}
                {properties.status == CanceledCommand && (
                    <Box>
                        <Typography variant={'body2'}>
                            <i>{t(properties.component)}</i> in project <b>{properties.projectTitle}</b> was canceled.
                        </Typography>
                    </Box>
                )}
            </>
        </NotificationContent>
    )
}
