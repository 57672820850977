import { Box, Link, Tooltip } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { DataGridPremium, GRID_CHECKBOX_SELECTION_COL_DEF, GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { concat, DataFrame, readCSV, toJSON } from 'danfojs'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'usehooks-ts'
import { useAppSelector } from '../../../app/hooks'
import useStoredColumnModel from '../../../hooks/useStoredColumnModel'
import useStoredFilterModel from '../../../hooks/useStoredFilterModel'
import {
    DashboardFilter,
    MetadataFieldTarget,
    MicroarrayWorkflow,
    RnaSeqWorkflow,
    ScRnaSeqWorkflow,
} from '../../../model/model'
import { useGetMetadataMapQuery } from '../../common-api/metadataFieldApiSlice'
import { WithIdName } from '../../sample/import/model'
import { determineColumnDefinitions } from '../../workbench/analysis/common/DataFrameGrid'
import GeneSignatureQuickViewDialog, {
    GeneSignatureQuickViewDialogData,
} from '../../workbench/analysis/common/GeneSignatureQuickViewDialog'
import GeneSignatureModuleDialog from '../../workbench/analysis/transcriptomics/common/differential_expression/GeneSignatureModuleDialog'
import GeneSignatureMostSimilarDialog from '../../workbench/analysis/transcriptomics/common/differential_expression/GeneSignatureMostSimilarDialog'
import GeneSignatureOverlapDialog from '../../workbench/analysis/transcriptomics/common/differential_expression/GeneSignatureOverlapDialog'
import GeneSignatureSimilarityMatrixDialog from '../../workbench/analysis/transcriptomics/common/differential_expression/GeneSignatureSimilarityMatrixDialog'
import { selectAllFilters, selectLogicalOperator, selectSignatureGenes } from '../dashboardSlice'
import { useLazyGetSignatureDataQuery } from '../queryApiSlice'
import SignatureTableGridToolbar from './SignatureTableGridToolbar'
import GeneCoPerturbationFormDialog from '../coperturbation/GeneCoPerturbationFormDialog'

type SignatureTableProps = {
    collections: string[]
    maxPValue: number
    logFCFilter: DashboardFilter | undefined
}

export default function SignatureTable({ collections, maxPValue, logFCFilter }: SignatureTableProps) {
    const { height = 0 } = useWindowSize()
    const filters = useAppSelector(selectAllFilters)
    const logicalOperator = useAppSelector(selectLogicalOperator)
    const genes = useAppSelector(selectSignatureGenes)

    const [resultDataFrame, setResultDataFrame] = useState<DataFrame | null>(null)

    const [getSignatureDataApi, { data, isFetching }] = useLazyGetSignatureDataQuery()

    // Create a debounced function for the API call
    const debouncedGetSignatureDataApi = useCallback(
        debounce((params) => {
            getSignatureDataApi(params)
        }, 400),
        [],
    )

    useEffect(() => {
        const params = {
            filters: filters,
            logicalOperator: logicalOperator,
            genes: genes,
            maxPvalue: maxPValue,
            logFoldChangeFilter: logFCFilter,
        }

        debouncedGetSignatureDataApi(params)

        return () => {
            debouncedGetSignatureDataApi.cancel()
        }
    }, [filters, logicalOperator, genes, maxPValue, logFCFilter, debouncedGetSignatureDataApi])

    useEffect(() => {
        if (data) {
            loadResultDataFrame(data.url)
        }
    }, [data])

    const loadResultDataFrame = (url?: string) => {
        if (url) {
            readCSV(url, { skipEmptyLines: 'greedy' }).then((df) => {
                setResultDataFrame(df)
            })
        } else {
            setResultDataFrame(new DataFrame([]))
        }
    }

    const filteredDatasets = useMemo((): WithIdName[] => {
        if (!resultDataFrame || !resultDataFrame.shape[0]) {
            return []
        }
        if (!collections || collections.length == 0) {
            return []
        }
        const dfs = []
        for (const c of collections) {
            const df = resultDataFrame
                .iloc({
                    rows: resultDataFrame['collection_id'].eq(parseInt(c)),
                })
                .resetIndex()
            if (df.shape[0] > 0) {
                dfs.push(df)
            }
        }

        if (dfs.length > 0) {
            const filteredDF = concat({ dfList: dfs, axis: 0 }) as DataFrame
            return (toJSON(filteredDF) as WithIdName[]) ?? []
        }
        return []
    }, [collections, resultDataFrame])

    return (
        <>
            <Box sx={{ height: `${Math.max(height - 360, 300)}px` }}>
                <GeneSignatureGrid
                    collections={collections}
                    filteredDatasets={filteredDatasets}
                    resultDataFrame={resultDataFrame}
                    isFetching={isFetching}
                    tableModelKey={'omicsbrowser_signature_table'}
                />
            </Box>
        </>
    )
}

export const GeneSignatureGrid = ({
    collections,
    filteredDatasets,
    resultDataFrame,
    isFetching,
    showBorder,
    width,
    tableModelKey,
}: {
    collections?: string[]
    filteredDatasets: WithIdName[]
    resultDataFrame: DataFrame | null
    isFetching: boolean
    showBorder?: boolean
    width?: number
    tableModelKey: string
}) => {
    const { t } = useTranslation()
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
    const [openGeneOverlapDialog, setOpenGeneOverlapDialog] = useState(false)
    const [openGeneSignatureSimilarityMatrixDialog, setOpenGeneSignatureSimilarityMatrixDialog] = useState(false)
    const [openGeneSignatureModuleDialog, setOpenGeneSignatureModuleDialog] = useState(false)
    const [openSimilarGeneSignatures, setOpenSimilarGeneSignatures] = useState(false)
    const [openGeneCoPerturbation, setOpenGeneCoPerturbation] = useState(false)
    const [openQuickViewDialog, setOpenQuickViewDialog] = useState(false)
    const [quickViewGeneSignatureData, setQuickViewGeneSignatureData] =
        useState<GeneSignatureQuickViewDialogData | null>(null)
    const { lookup: metadataMap } = useGetMetadataMapQuery(MetadataFieldTarget.Sample)

    const [filterModel, setFilterModel] = useStoredFilterModel(tableModelKey, {
        items: [],
        quickFilterLogicOperator: undefined,
        quickFilterValues: [],
    })

    const getResultLink = (params: GridRenderCellParams) => {
        let workflowPath = ''
        switch (params.row['workflow']) {
            case MicroarrayWorkflow:
                workflowPath = 'microarray'
                break
            case RnaSeqWorkflow:
                workflowPath = 'rnaseq'
                break
            case ScRnaSeqWorkflow:
                workflowPath = 'scrnaseq'
                break
        }
        if (params.row['analysis_id']) {
            return `/analysis/${workflowPath}/${params.row['analysis_id']}/deg?key=${params.row['computation_result_key']}`
        }
        return null
    }

    const renderQuickViewLink = (params: GridRenderCellParams) => {
        return (
            <Link
                onClick={() => {
                    setQuickViewGeneSignatureData({
                        geneSignatureId: params.row['id'],
                        name: params.row['name'],
                        caseValue: params.row['case'],
                        controlValue: params.row['control'],
                        analysisUri: getResultLink(params),
                    })
                    setOpenQuickViewDialog(true)
                }}
            >
                {params.value}
            </Link>
        )
    }

    const columns = useMemo(() => {
        let columns = filteredDatasets[0] ? Object.keys(filteredDatasets[0]) : []
        columns = columns.filter(
            (x) =>
                x !== 'name' &&
                x !== 'case' &&
                x !== 'control' &&
                x !== 'workflow' &&
                x !== 'id' &&
                x !== 'collection_id' &&
                x !== 'analysis_id' &&
                x !== 'computation_result_key',
        )
        columns.sort((a, b) => (metadataMap.get(a)?.index ?? 0) - (metadataMap.get(b)?.index ?? 0))
        return [
            {
                field: 'name',
                headerName: t('name'),
                groupable: false,
                width: 200,
                renderCell: renderQuickViewLink,
            },
            {
                field: 'case',
                headerName: t('case'),
                groupable: false,
                width: 200,
                renderCell: renderQuickViewLink,
            },
            {
                field: 'control',
                headerName: t('control'),
                groupable: false,
                width: 200,
            },
            ...columns.map((c) => {
                const [colType, filterOperators, renderCell] = determineColumnDefinitions(c, resultDataFrame, 2)
                return {
                    field: c,
                    renderHeader: () => (
                        <>
                            <Tooltip title={metadataMap.get(c)?.description} arrow>
                                <span>{c}</span>
                            </Tooltip>
                        </>
                    ),
                    colType: colType,
                    filterOperators: filterOperators,
                    renderCell: renderCell,
                    groupable: false,
                    width: 150,
                }
            }),
        ] as GridColDef[]
    }, [resultDataFrame, filteredDatasets, metadataMap])

    const defaultHiddenColumns = useMemo(() => {
        return columns.map((c) => c.field).filter((c) => metadataMap.get(c)?.visibleByDefault === false)
    }, [columns, metadataMap])

    const [columnModel, setColumnModel] = useStoredColumnModel(tableModelKey, defaultHiddenColumns)

    const disableGeneOverlap = useMemo(() => {
        return selectionModel.length < 2 || selectionModel.length > 100
    }, [selectionModel, collections])

    const disableGeneSignatureSimilarityMatrix = useMemo(() => {
        return selectionModel.length < 2 || selectionModel.length > 100
    }, [selectionModel, collections])

    const disableDifferentialExpressionMatrix = useMemo(() => {
        return selectionModel.length < 2 || selectionModel.length > 100
    }, [selectionModel, collections])

    const disableSimilarGeneSignatures = useMemo(() => {
        return selectionModel.length !== 1
    }, [selectionModel, collections])

    const disableCoPerturbedGenes = useMemo(() => {
        return selectionModel.length == 0
    }, [selectionModel, collections])

    return (
        <>
            <DataGridPremium
                sx={{
                    border: showBorder ? '1px solid #ddd' : 0,
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#EEE',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        pt: 1,
                        pb: 1,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0,
                    },
                    ...(width ? { width: `${width}px` } : {}),
                }}
                rows={filteredDatasets}
                rowHeight={40}
                columns={columns}
                loading={isFetching}
                rowSelectionModel={selectionModel}
                initialState={{
                    pinnedColumns: {
                        left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                    },
                }}
                columnVisibilityModel={columnModel}
                onColumnVisibilityModelChange={setColumnModel}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel)
                }}
                disableRowSelectionOnClick
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                checkboxSelection
                keepNonExistentRowsSelected
                pageSizeOptions={[10, 25, 50, 100]}
                pagination={true}
                slots={{
                    toolbar: SignatureTableGridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        overlapCallback: () => {
                            setOpenGeneOverlapDialog(true)
                        },
                        disabledOverlap: disableGeneOverlap,
                        similarityCallback: () => {
                            setOpenGeneSignatureSimilarityMatrixDialog(true)
                        },
                        disableSimilarity: disableGeneSignatureSimilarityMatrix,
                        geneModulesCallback: () => {
                            setOpenGeneSignatureModuleDialog(true)
                        },
                        disableGeneModules: disableDifferentialExpressionMatrix,
                        similarGeneSignaturesCallback: () => {
                            setOpenSimilarGeneSignatures(true)
                        },
                        disableSimilarGeneSignatures: disableSimilarGeneSignatures,
                        coPerturbedGenesCallback: () => {
                            setOpenGeneCoPerturbation(true)
                        },
                        disabledCoPerturbedGenes: disableCoPerturbedGenes,
                    },
                }}
            />
            <GeneSignatureOverlapDialog
                geneSignatureIds={selectionModel as number[]}
                raw={false}
                openDialog={openGeneOverlapDialog}
                handleCloseDialog={() => {
                    setOpenGeneOverlapDialog(false)
                }}
            />
            <GeneSignatureSimilarityMatrixDialog
                geneSignatureIds={selectionModel as number[]}
                raw={false}
                openDialog={openGeneSignatureSimilarityMatrixDialog}
                handleCloseDialog={() => {
                    setOpenGeneSignatureSimilarityMatrixDialog(false)
                }}
            />
            <GeneSignatureModuleDialog
                geneSignatureIds={selectionModel as number[]}
                openDialog={openGeneSignatureModuleDialog}
                handleCloseDialog={() => {
                    setOpenGeneSignatureModuleDialog(false)
                }}
            />
            <GeneSignatureMostSimilarDialog
                geneSignatureId={(selectionModel as number[])[0]}
                name={
                    filteredDatasets.find((ds) => {
                        return ds.id == (selectionModel as number[])[0]
                    })?.name
                }
                openDialog={openSimilarGeneSignatures}
                handleCloseDialog={() => {
                    setOpenSimilarGeneSignatures(false)
                }}
            />
            {quickViewGeneSignatureData && (
                <GeneSignatureQuickViewDialog
                    geneSignatureId={quickViewGeneSignatureData.geneSignatureId}
                    name={quickViewGeneSignatureData.name}
                    caseValue={quickViewGeneSignatureData.caseValue}
                    controlValue={quickViewGeneSignatureData.controlValue}
                    analysisUri={quickViewGeneSignatureData.analysisUri}
                    openDialog={openQuickViewDialog}
                    handleCloseDialog={() => {
                        setOpenQuickViewDialog(false)
                        setQuickViewGeneSignatureData(null)
                    }}
                />
            )}
            <GeneCoPerturbationFormDialog
                geneSignatureIds={selectionModel as number[]}
                openDialog={openGeneCoPerturbation}
                handleCloseDialog={() => {
                    setOpenGeneCoPerturbation(false)
                }}
            />
        </>
    )
}
