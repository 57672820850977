import { apiSlice } from '../../../../../../app/apiSlice'
import { GeneSignatureSimilarityDto } from '../../../../../sample/import/model'
import { SimilarityMetricType } from '../../../../../../model/model'

export interface ListSimilaritiesRequest {
    geneSignatureId: number
    collectionIds: number[]
}

export interface GeneSignatureOverlapEntry {
    gene: string
    consistent: boolean
    signatureLogFoldChangePairs: Record<string, number>
}

export interface GetGeneOverlapRequest {
    geneSignatureIds: number[]
    pValThreshold: number
    minAbsLogFoldChange: number
    raw: boolean
}

export interface GetSimilarityMatrixRequest {
    geneSignatureIds: number[]
    raw: boolean
    metric: SimilarityMetricType
}

export interface SimilarityMatrix {
    header: string[]
    data: number[][]
    overlap: number[][]
}

export interface GetGeneModuleRequest {
    geneSignatureIds: number[]
    genes: string[]
}

export interface GeneModule {
    xNames: string[]
    yNames: string[]
    data: number[][][]
}

export const geneSignatureApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getGeneOverlap: builder.mutation<GeneSignatureOverlapEntry[], GetGeneOverlapRequest>({
            query: (req) => ({
                url: '/private/gene-signature/overlap',
                method: 'POST',
                body: req,
            }),
        }),
        listSimilarities: builder.query<GeneSignatureSimilarityDto[], ListSimilaritiesRequest>({
            query: (req) => ({
                url: `/private/gene-signature/similarity/list`,
                params: {
                    geneSignatureId: req.geneSignatureId,
                    collectionIds: req.collectionIds,
                },
            }),
        }),
        getSimilarityMatrix: builder.query<SimilarityMatrix, GetSimilarityMatrixRequest>({
            query: (req) => ({
                url: `/private/gene-signature/similarity/matrix`,
                method: 'POST',
                body: req,
            }),
        }),
        getGeneModule: builder.query<GeneModule, GetGeneModuleRequest>({
            query: (req) => ({
                url: '/private/gene-signature/module',
                method: 'POST',
                body: req,
            }),
        }),
    }),
})

export const {
    useGetGeneOverlapMutation,
    useLazyListSimilaritiesQuery,
    useLazyGetSimilarityMatrixQuery,
    useLazyGetGeneModuleQuery,
} = geneSignatureApiSlice
