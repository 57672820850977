import { useMemo } from 'react'
import { apiSlice } from '../../app/apiSlice'
import { MetadataField, MetadataFieldDetail, MetadataFieldTarget, MetadataFieldType } from '../../model/model'

export type CreateMetadataFieldRequest = {
    name: string
    index: number
    type: MetadataFieldType
    ontologyId?: number | null
    options?: string[] | null
    isPatientId: boolean
    isSampleId: boolean
    visibleByDefault: boolean
    description: string
}

export type UpdateMetadataFieldRequest = CreateMetadataFieldRequest & {
    id: number
}

export const metadataFieldApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMetadataFields: builder.query<MetadataField[], MetadataFieldTarget>({
            query: (target: MetadataFieldTarget) => `/private/metadata-field/list?target=${target}`,
        }),
        getMetadataField: builder.query<MetadataField, string>({
            query: (field: string) => `/private/metadata-field/get?field=${field}`,
        }),
        listAdminMetadataFields: builder.query<MetadataFieldDetail[], void>({
            query: () => `/private/metadata-field/admin/list`,
        }),
        createMetadataField: builder.mutation<MetadataFieldDetail, CreateMetadataFieldRequest>({
            query: (req) => ({
                url: '/private/metadata-field/admin/create',
                method: 'POST',
                body: req,
            }),
        }),
        updateMetadataField: builder.mutation<MetadataFieldDetail, UpdateMetadataFieldRequest>({
            query: (req) => ({
                url: '/private/metadata-field/admin/update',
                method: 'PUT',
                body: req,
            }),
        }),
        deleteMetadataFields: builder.mutation<number[], number[]>({
            query: (req) => ({
                url: '/private/metadata-field/admin/delete',
                method: 'DELETE',
                body: req,
            }),
        }),
    }),
})

export const {
    useGetMetadataFieldsQuery,
    useLazyListAdminMetadataFieldsQuery,
    useGetMetadataFieldQuery,
    useCreateMetadataFieldMutation,
    useUpdateMetadataFieldMutation,
    useDeleteMetadataFieldsMutation,
    endpoints: { getMetadataFields, getMetadataField },
} = metadataFieldApiSlice

export function useGetMetadataMapQuery(target: MetadataFieldTarget): {
    isLoading: boolean
    isError: boolean
    lookup: Map<string, MetadataField>
    data: MetadataField[] | undefined
} {
    const query = useGetMetadataFieldsQuery(target)

    const metadataMap = useMemo(() => {
        const map = new Map<string, MetadataField>()
        if (query.data) {
            for (const field of query.data) {
                map.set(field.name, field)
            }
        }

        return map
    }, [query.data])

    return {
        isError: query.isError,
        isLoading: query.isLoading,
        data: query.data,
        lookup: metadataMap,
    }
}
